<template>
	<div class="formulaire__comptabilite">
		<svg class="logo" fill="#dcc395" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xml:space="preserve" viewBox="0 0 100 125" version="1.1" y="0px" x="0px"><path d="m50.004 6.2681-6.9284 4.7885h13.813l-6.885-4.7885zm-31.113 10.212v31.117l20.909 14.45 8.5783-6.6555c0.98788-0.76645 2.3334-0.73894 3.2853-0.02481 0.01282 0.0093 0.02605 0.01422 0.03928 0.02481l8.5163 6.6079 20.891-14.523v-30.997h-62.219zm12.755 10.842h36.707c1.4973 0 2.7106 1.2137 2.7106 2.7106s-1.2132 2.7126-2.7106 2.7126h-36.707c-1.4969 0-2.7106-1.2157-2.7106-2.7126 0.000001-1.4969 1.2137-2.7106 2.7106-2.7106zm-18.178 4.1951-8.9194 6.1654 8.9194 6.1654v-12.331zm73.063 0.1468v12.041l8.6631-6.0207-8.6631-6.0207zm-54.885 9.7547h36.707c1.4973 0 2.7106 1.2157 2.7106 2.7126s-1.2132 2.7106-2.7106 2.7106h-36.707c-1.4969 0-2.7106-1.2137-2.7106-2.7106 0.000001-1.4969 1.2137-2.7126 2.7106-2.7126zm65.788 1.3108-32.725 22.751 32.791 25.435-0.0662-48.186zm-94.934 0.132 0.0662 48.066 32.74-25.395-32.806-22.671zm47.541 18.103-42.244 32.767 84.338-0.116-42.094-32.651z"/></svg>
		<h3 class="mt-10">Le service administratif</h3>
		<v-form 
			v-model="opt_form.valide"
			mode="aggressive"
			ref="form"
		>
			<v-select
				outlined
				:items="opt_form.liste_motif"
				v-model="objet"
				label="Objet de votre demande"
				item-text="label"
				item-value="code"
				:rules="opt_form.rules.objet"
				class="mt-8"
			></v-select>
			<InputSearchCab 
				@change="cab_change"
				class="mt-1"
				require
				v-if="opt_form.motif_client.includes(objet)"
			>
			</InputSearchCab>
			<v-text-field 
				outlined
				v-model="cab"
				:rules="opt_form.rules.cab"
				v-show="false"
				v-if="opt_form.motif_client.includes(objet)"
			></v-text-field>
			<v-textarea
				outlined
				name="input-7-4"
				label="Rédigez votre demande"
				v-model="content"
				:rules="opt_form.rules.content"
				class=""
			></v-textarea>
			<InputFileUpload
				colorBack="#3d2f59"
				class="mt-4 mb-10"
				@change="files_changes"
			></InputFileUpload>
			<v-btn 
				class="mt-0" 
				depressed 
				color="#dcc395" 
				:dark="opt_form.valide"
				:disabled="!opt_form.valide"
				@click="send"
			>
				Envoyer
			</v-btn>
			<v-btn 
				class="ml-4" 
				depressed 
				@click="$router.go(-1);"
			>
				Retour
			</v-btn>		
		</v-form>
	</div>
</template>

<script>
	import InputSearchCab from '../InputCabSearch.vue'
	import InputFileUpload from '../InputFileUpload.vue'
	import { mapActions, mapMutations } from 'vuex';

	export default {
		name: 'Comptabilite',
		components : {
			InputSearchCab,
			InputFileUpload
		},
		data: () => ({
			cab: "",
			objet: "",
			content: "",
			files: [],
			opt_form: {
				liste_motif : [],
				motif_interne : [],
				motif_client : [],
				cab_error : false,
				lazy: false,
				valide: false,
				rules : {
					content : [
						v => v != "" ? true : 'Le message est requis',
						v => v.length > 5 ? true : "Le message est trop court"
					],
					objet : [
						v => v != "" ? true : "Le type est requis"
					]
				}
			},
		}),
		created: function() {
			var _this = this;
			this.getAdminListMotif()
			.then(resultat => {
				_this.opt_form.liste_motif = resultat;
				_this.opt_form.motif_interne = resultat.filter(x => {
					return x.type == "interne"
				}).map(x => {
					return x.code
				})

				_this.opt_form.motif_client = resultat.filter(x => {
					return x.type == "client"
				}).map(x => {
					return x.code
				})
			})
		},
		methods: {
			...mapActions('formulaires', [
				"sendAdminInterne",
				"sendAdminClient",
				"getAdminListMotif"
			]),
			...mapMutations('global', [
				"sendSuccessNotif"
			]),
			cab_change: function(e) {
				this.cab = e;
			},
			files_changes: function(e) {
				this.files = e;
			},
			send: function() {				
				if(this.opt_form.motif_client.includes(this.objet)) {
					this.sendClient()
				} else {
					this.sendInterne()
				}
			},
			sendInterne: function() {
				var _this = this;
				this.sendAdminInterne({
					objet: this.objet,
					content: this.content,
					files: this.files.map(x => {
						return x.file_id
					}),
				})
				.then(() => {
					_this.sendSuccessNotif("Opération effectuée")
					_this.$router.push('/');
				})
			},
			sendClient: function() {
				var _this = this;
				this.sendAdminClient({
					cab: this.cab,
					objet: this.objet,
					content: this.content,
					files: this.files.map(x => {
						return x.file_id
					}),
				})
				.then(() => {
					_this.sendSuccessNotif("Opération effectuée")
					_this.$router.push('/');
				})
			}
		},
	};
</script>

<style lang="scss" scoped>
	.formulaire__comptabilite {
		width: 40%;
		border: 4px solid #f1f1f1;
		border-radius: 16px;
		margin-top: 30px;
		padding: 20px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		overflow: display;
		padding-bottom: 30px;

		.logo
		{
			width: 50px;
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			top: -25px;
			left:0;
			right: 0;
			z-index: 1000;
		}
	}
</style>